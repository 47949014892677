<template>
  <div class="elv-reconciliation-transactions-table-wrapper">
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData.list"
      border
      stripe
      width="100%"
      show-summary
      row-key="transactionId"
      :summary-method="setSummaries"
      height="100%"
      class="elv-reconciliation-transactions-table"
      header-cell-class-name="elv-reconciliation-transactions-table-header__cell"
      header-row-class-name="elv-reconciliation-transactions-table-header"
      row-class-name="elv-reconciliation-transactions-table-row"
      cell-class-name="elv-reconciliation-transactions-table-row__cell"
      @selection-change="onSelectionChange"
      @row-click="onCellClicked"
    >
      <el-table-column
        type="selection"
        width="40"
        :selectable="selectable"
        fixed="left"
        reserve-selection
        label-class-name="elv-reconciliation-transactions-table-row__index is-last-column"
      />
      <el-table-column width="110" :label="t('common.type')">
        <template #default="{ row }">
          <div class="elv-reconciliation-transactions-table-row__cell-type">
            <SvgIcon :name="typeData(row).value?.icon" width="14" height="14" />
            <span>{{ transformI18n(typeData(row).value?.text) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150" :label="t('common.dateTime')">
        <template #default="{ row }">
          <DateTimeCell :params="{ data: row }" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.From/To')" min-width="314">
        <template #default="{ row }">
          <FromToCell :params="{ data: row }" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Currency')"
        width="110"
        class-name="elv-reconciliation-transactions-table-row__currency"
        label-class-name="elv-reconciliation-transactions-table-row__currency"
      >
        <template #default="{ row }">
          <CurrencyCell :params="{ data: row, value: { cellName: 'Currency' } }" type="transactions" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Amount')"
        width="120"
        label-class-name="elv-reconciliation-transactions-table-row__amount el-table-fixed-column--right is-first-column"
        align="right"
        class-name="elv-reconciliation-transactions-table-row__amount"
        header-align="right"
        fixed="right"
      >
        <template #default="{ row }">
          <TextCell
            :text="formatNumber(row.amount ?? 0, 20)"
            align="right"
            :weight="500"
            :title-desc="formatNumber(row.amount ?? 0, 20)"
          />
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-transactions-list-pagination"
      size-popper-class="elv-transactions-pagination__sizes-popper"
      :limit="props.transactionsParams.limit"
      :current-page="props.transactionsParams.page"
      :total="props.tableData.totalCount"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>

<script setup lang="ts">
import { flatMap } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import { transformI18n } from '@/i18n/index'
import { transactionType } from '@/config/index'
import type { TableColumnCtx } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import { TransactionItemType } from '#/TransactionsTypes'
import TextCell from '@/components/Base/Table/TextCell.vue'
import FromToCell from '@/components/Reports/Cell/FromToCell.vue'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'

const props = defineProps({
  tableData: {
    type: Object,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  transactionsParams: {
    type: Object,
    required: true
  },
  tableDataSelected: {
    type: Array,
    required: true
  },
  selectedTotalAmount: {
    type: Number,
    required: true
  },
  disabledSelected: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()

const emit = defineEmits(['onChangePage', 'onChangePageSize', 'onSelectionChange'])

const tableListRef = useTemplateRef('tableListRef')
const overlayDrawerRef = ref()
const overlayDrawerData = reactive({
  type: 'contact',
  drawerTitle: 'Contact',
  currentData: {},
  isBatch: false
})

interface SummaryMethodProps<T = any> {
  columns: TableColumnCtx<T>[]
  data: T[]
}

const transactionEditOverlayRef = ref()

const typeData: any = useComputedHook((row: TransactionItemType) => {
  let data: any = {}
  const transactionTypeList = flatMap(transactionType, 'list')
  transactionTypeList.forEach((item) => {
    if (item.value === row.type) {
      data = {
        icon: item.icon,
        text: item.label
      }
    }
  })
  return data
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const selectable = (row: any) => {
  if (props.disabledSelected === '') return true
  return row.currency === props.disabledSelected
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    console.log(row)
    overlayDrawerData.currentData = {}
    if (
      e.target?.className === 'elv-financials-cell-fromTo-main__address' ||
      e.target?.className === 'elv-financials-cell-fromTo-item-main__logo' ||
      e.target?.className?.baseVal === 'elv-financials-cell-fromTo-item-main__logo' ||
      e.target?.className === 'elv-financials-cell-fromTo-item-main'
    ) {
      const direction = e.target?.attributes['data-type'].value
      switch (direction) {
        case 'from':
          if (row?.fromEntityAccount?.address) {
            overlayDrawerData.drawerTitle = 'Account'
            overlayDrawerData.currentData = {
              value: row?.fromAddress,
              source: { ...row?.source },
              platform: { ...row?.fromEntityAccount?.platform },
              ...row?.fromEntityAccount
            }
          } else if (row?.fromAddressContact?.role) {
            overlayDrawerData.drawerTitle = 'Contact'
            overlayDrawerData.currentData = {
              value: row?.fromAddress,
              source: { ...row?.source },
              ...row?.fromAddressContact
            }
          } else {
            switch (row?.direction) {
              case 'OUT':
                overlayDrawerData.drawerTitle = 'Account'
                overlayDrawerData.currentData = {
                  value: row?.fromAddress,
                  source: { ...row?.source }
                }
                break
              default:
                overlayDrawerData.drawerTitle = 'Contact'
                overlayDrawerData.currentData = {
                  value: row?.fromAddress,
                  source: { ...row?.source }
                }
                break
            }
          }
          break
        default:
          if (row?.toEntityAccount?.address) {
            overlayDrawerData.drawerTitle = 'Account'
            overlayDrawerData.currentData = {
              value: row?.toAddress,
              source: { ...row?.source },
              platform: { ...row?.toEntityAccount?.platform },
              ...row?.toEntityAccount
            }
          } else if (row?.toAddressContact?.role) {
            overlayDrawerData.drawerTitle = 'Contact'
            overlayDrawerData.currentData = {
              value: row?.toAddress,
              source: { ...row?.source },
              ...row?.toAddressContact
            }
          } else {
            switch (row?.direction) {
              case 'IN':
                overlayDrawerData.drawerTitle = 'Account'
                overlayDrawerData.currentData = {
                  value: row?.toAddress,
                  source: { ...row?.source }
                }
                break
              default:
                overlayDrawerData.drawerTitle = 'Contact'
                overlayDrawerData.currentData = {
                  value: row?.toAddress,
                  source: { ...row?.source }
                }
                break
            }
          }
          break
      }
      overlayDrawerData.isBatch = false
      overlayDrawerData.type = 'transaction'
      overlayDrawerRef.value?.onCheckDrawerStatus()
    } else if (
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__copy' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__txHash' &&
      e.target?.className?.animVal !== 'elv-duplicates-table-ignore' &&
      e.target?.href?.animVal !== '#icon-duplicates-Ignore'
    ) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewDetail
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      overlayDrawerData.currentData = row
      transactionEditOverlayRef.value?.onCheckDrawerStatus()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

// eslint-disable-next-line no-unused-vars
const onUpdateAccountOrContact = (data: any) => {
  overlayDrawerData.currentData = data
}

const onSelectionChange = (val: any) => {
  emit('onSelectionChange', val)
}

const setSummaries = (param: SummaryMethodProps) => {
  const { columns } = param
  const sums: string[] = []
  columns.forEach((column, index) => {
    if (index === 1) {
      sums[index] = `${t('common.selected')}: ${formatNumber(props.tableDataSelected.length)}`
      return
    }

    if (index === 5) {
      sums[index] = `${props.selectedTotalAmount}`
      return
    }

    // eslint-disable-next-line no-param-reassign
    column.rowSpan = 0
    // eslint-disable-next-line no-param-reassign
    column.colSpan = 0
  })
  nextTick(() => {
    setTimeout(() => {
      const current = tableListRef.value?.$el
        .querySelector('.el-table__footer-wrapper')
        .querySelector('.el-table__footer')
      const cell = current.rows[0].querySelectorAll('.el-table__cell')
      cell?.forEach((element: any, index: number) => {
        if (index > 1 && index < 5) {
          // eslint-disable-next-line no-param-reassign
          element.style.display = 'none'
        }
      })
      cell[2].style.display = 'none'
      cell[1].colSpan = '4'
      cell[1].firstChild.className = 'cell elv-reconciliation-transactions-table-row__total'
      cell[5].firstChild.className = 'cell elv-reconciliation-transactions-table-row__total-amount'
    }, 10)
  })
  return sums
}

defineExpose({ tableListRef })
</script>

<style lang="scss">
.elv-reconciliation-transactions-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-table-header-border: 1px solid #e4e7eb;
  --el-fill-color-lighter: #f9fafb;
  // width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  &.is-scrolling-middle,
  &.is-scrolling-right {
    .elv-reconciliation-transactions-table-header
      .el-table-fixed-column--left.elv-reconciliation-transactions-table-row__index.is-last-column.el-table__cell {
      border-right: none !important;
    }
  }

  &.is-scrolling-none {
    .el-table__body-wrapper {
      .elv-reconciliation-transactions-table-row .elv-reconciliation-transactions-table-row__currency {
        border-right: 0px !important;
      }

      .is-right.el-table-fixed-column--right.is-first-column {
        border-left: 1px solid #edf0f3 !important;
      }
    }

    .el-table__header-wrapper {
      .elv-reconciliation-unmatched-table-header .elv-reconciliation-transactions-table-row__currency {
        border-right: 0px !important;
      }

      .is-right.el-table-fixed-column--right.is-first-column {
        border-left: 1px solid #e4e7eb !important;
      }
    }
  }

  .elv-reconciliation-transactions-table-header {
    background: #eef4fb;

    .elv-reconciliation-transactions-table-header__cell {
      background: #eef4fb !important;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &.el-table-fixed-column--left {
        background: #eef4fb !important;
        position: relative !important;
        border-right: var(--el-table-header-border) !important;
        left: 0px !important;

        &.elv-reconciliation-transactions-table-row__index {
          position: sticky !important;
          z-index: 10;

          .cell {
            display: none;
          }
        }
      }

      &.elv-reconciliation-transactions-table-row__amount.is-right {
        position: sticky !important;
        z-index: 10;
        right: 0;
      }

      &.elv-reconciliation-transactions-table-row__currency.el-table-fixed-column--left.is-last-column::before {
        box-shadow: none !important;
      }

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-reconciliation-transactions-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    &.elv-reconciliation-transactions-table-row__amount .elv-base-cell-text {
      white-space: nowrap;
      overflow: hidden;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    .elv-reconciliation-transactions-table-row__cell-activity {
      font-weight: 500;
    }

    .elv-reconciliation-transactions-table-row__cell-type {
      display: flex;
      align-items: center;
      font-size: 11px;
      font-weight: 400;
      color: #636b75;
      white-space: nowrap;

      svg {
        margin-right: 8px;
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-table__footer-wrapper {
    height: 42px;

    .el-table__footer {
      height: 100%;

      .cell.elv-reconciliation-transactions-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-reconciliation-transactions-table-row__total-amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.elv-transactions-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
<style lang="scss" scoped>
.elv-reconciliation-transactions-table-wrapper {
  width: calc(100% - 6px);
  height: calc(100% - 54px);
  position: relative;
}
</style>
